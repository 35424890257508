<template>
  <b-row>
    <b-col cols="3">
      <TreeView2></TreeView2>
    </b-col>
    <b-col cols="9">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">공지사항 작성</h5>
        </b-card-body>
        <div class="p-3 align-items-center">
        
        <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <b-form-select v-model="selected" :options="[
                { value: null, text: '항목을 선택하세요.', disabled: true },
                { value: '1', text: '회계보고' },
                { value: '2', text: '공지/보수' },
                { value: '3', text: '행사' },
                { value: '4', text: '생활안내' },
                { value: '5', text: '기타' }
                ]"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="3"><b-form-datepicker id="example-datepicker" v-model="value" class="mb-2" placeholder="공지 기한"></b-form-datepicker></b-col>

          <b-col cols="12" lg="8">
            <b-form-group id="input2">
              <b-form-input type="text" id="input2" placeholder="제목을 입력해 주세요."
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group id="input3">
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="12">
            <froala :tag="'textarea'" :config="config" v-model="content"></froala>
          </b-col>
        </b-row>

        <div class="d-flex justify-content-end">
          <b-button
            type="button"
            variant="outline-primary"
            class="d-flex align-items-center px-3 mt-3"
          >등록</b-button>
        </div>
      </b-form>
      </div>
      </b-card>
    </b-col>
  </b-row>
  
</template>

<script>

import TreeView2 from '../components/apps/TreeView2';
export default {
  name: "NoticeWrite",

  data: () => ({
    selected: null,
    content: "",
    config: {
          
      }

    
  }),
  components: {TreeView2},
  methods: {
    
  },
  mounted() {
  },
};
</script>
