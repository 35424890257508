var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('TreeView2')],1),_c('b-col',{attrs:{"cols":"9"}},[_c('b-card',{staticClass:"mb-4",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"p-3"},[_c('h5',{staticClass:"card-title mb-0"},[_vm._v("공지사항 작성")])]),_c('div',{staticClass:"p-3 align-items-center"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-select',{attrs:{"options":[
              { value: null, text: '항목을 선택하세요.', disabled: true },
              { value: '1', text: '회계보고' },
              { value: '2', text: '공지/보수' },
              { value: '3', text: '행사' },
              { value: '4', text: '생활안내' },
              { value: '5', text: '기타' }
              ]},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"example-datepicker","placeholder":"공지 기한"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('b-form-group',{attrs:{"id":"input2"}},[_c('b-form-input',{attrs:{"type":"text","id":"input2","placeholder":"제목을 입력해 주세요."}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"id":"input3"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('froala',{attrs:{"tag":'textarea',"config":_vm.config},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"d-flex align-items-center px-3 mt-3",attrs:{"type":"button","variant":"outline-primary"}},[_vm._v("등록")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }